<template>
  <div class="form flex flex-col justify-center py-auto sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white dark:bg-gray-900 py-8 px-4 shadow sm:rounded-lg sm:px-10 shadow-sm">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 class="mt-3 mb-10 text-center text-2xl font-bold text-green-500">
            Sign up to your account
          </h2>
        </div>

        <form class="space-y-6" @submit.prevent="register">
          <div>
            <label for="email" class="block  font-medium text-gray-700 dark:text-white ">
              Email address
            </label>
            <div class="mt-1">
              <input
                v-model="email"
                id="email"
                name="email"
                type="email"
                autocomplete="off"
                required
                class="
                   appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                  "
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block  font-medium text-gray-700 dark:text-white "
            >
              Password
            </label>
            <div class="mt-1">
              <input
                v-model="password"
                id="password"
                name="password"
                type="password"
                autocomplete="off"
                required
                class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                  "
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block  font-medium text-gray-700 dark:text-white"
            >
              Confirm Password
            </label>
            <div class="mt-1">
              <input
                v-model="confirmPassword"
                id="confirm-password"
                name="confirm-password"
                type="password"
                autocomplete="off"
                required
                class="
                    appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:border-green-500 dark:focus:border-green-500
                      visited:bg-gray:900
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                  "
              />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                class="
                    h-4
                    w-4
                    text-green-600
                    focus:ring-green-500
                    border-gray-300
                    rounded
                  "
              />
              <label for="remember_me" class="ml-2 block  text-gray-900">
                Remember me
              </label>
            </div>

            <div class="">
              <router-link
                to="Login"
                href="#"
                class="font-medium text-green-600 hover:text-green-500"
              >
                Already have an account ?
              </router-link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="
                  w-full
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  
                  font-medium
                  text-white
                  bg-green-600
                  hover:bg-green-700
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-green-500
                "
            >
              Sign up
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    register() {
      if (this.password === this.confirmPassword) {
        this.$store
          .dispatch("FIREABASE_REGISTER", {
            email: this.email,
            password: this.password,
            vm: this
          })
      } else {
        alert("Password must be same");
      }
    },
  },
};
</script>

<style>
.form {
  min-height: calc(100vh - 64px);
}
</style>
